export type MapType = 'navy' | 'army' | 'air-force' | 'army-reserve' | 'event' | 'adfc-contact-us' | undefined | null

//export const mapId = process.env.NEXT_PUBLIC_GOOGLE_MAP_ID ?? ''

export const mapOptions: google.maps.MapOptions = {
  center: {
    lat: -25.2744,
    lng: 133.7751,
  },
  zoom: 4.6,
  streetViewControl: false,
  mapTypeControl: false,
}

export function getKey(type: MapType): NodeJS.ProcessEnv[keyof NodeJS.ProcessEnv] {
  // if (process.env.NODE_ENV !== 'production') {
  //   return process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
  // }

  switch (type) {
    case 'navy':
      return `${process.env.NAVY_LOCATIONS_GOOGLE_MAPS_API_KEY}`

    case 'army':
      return `${process.env.ARMY_LOCATIONS_GOOGLE_MAPS_API_KEY}`

    case 'air-force':
      return `${process.env.AIR_FORCE_LOCATIONS_GOOGLE_MAPS_API_KEY}`

    case 'army-reserve':
      return `${process.env.ARMY_RESERVE_LOCATOR_GOOGLE_MAPS_API_KEY}`

    case 'event':
      return `${process.env.EVENT_LOCATIONS_GOOGLE_MAPS_API_KEY}`

    case 'adfc-contact-us':
      return `${process.env.ADFC_LOCATIONS_CONTACT_US_GOOGLE_MAPS_API_KEY}`

    default:
      return process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
  }
}

export function getMapId(type: MapType): NodeJS.ProcessEnv[keyof NodeJS.ProcessEnv] {
  switch (type) {
    case 'navy':
      return `${process.env.NAVY_LOCATIONS_GOOGLE_MAPS_ID}`

    case 'army':
      return `${process.env.ARMY_LOCATIONS_GOOGLE_MAPS_ID}`

    case 'air-force':
      return `${process.env.AIR_FORCE_LOCATIONS_GOOGLE_MAPS_ID}`

    case 'army-reserve':
      return `${process.env.ARMY_RESERVE_LOCATOR_GOOGLE_MAPS_ID}`

    case 'event':
      return `${process.env.EVENT_LOCATIONS_GOOGLE_MAPS_ID}`

    case 'adfc-contact-us':
      return `${process.env.ADFC_LOCATIONS_CONTACT_US_GOOGLE_MAPS_ID}`

    default:
      return process.env.NEXT_PUBLIC_GOOGLE_MAPS_ID
  }
}
