import { Cluster } from '@googlemaps/markerclusterer'

export interface MarkerItem {
  id: string
  name: string
  latitude: number
  longitude: number
  isActive?: boolean
}

const setAttributes = (element: HTMLElement | SVGElement, attributes: Record<string, string>) =>
  Object.entries(attributes).forEach(([key, value]) => {
    element.setAttribute(key, value)
  })

export const markerElement = (name: string, color = 'var(--marker-color)') => {
  const iconElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  setAttributes(iconElement, {
    xmlns: 'http://www.w3.org/2000/svg',
    'data-name': name,
    width: '41',
    height: '40',
    viewBox: '0 0 41 40',
    fill: 'none',
  })

  const pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  setAttributes(pathElement, {
    'fill-rule': 'evenodd',
    'clip-rule': 'evenodd',
    d: 'M10.1968 26.0612C12.4051 29.3251 15.7454 32.8598 20.2176 36.6654C24.6898 32.8598 28.0301 29.3251 30.2384 26.0612C32.4468 22.7973 33.5509 19.7765 33.5509 16.9987C33.5509 12.832 32.2107 9.51259 29.5301 7.04036C26.8496 4.56814 23.7454 3.33203 20.2176 3.33203C16.6898 3.33203 13.5857 4.56814 10.9051 7.04036C8.22455 9.51259 6.88428 12.832 6.88428 16.9987C6.88428 19.7765 7.98844 22.7973 10.1968 26.0612ZM20.2176 21.6654C21.5986 21.6654 22.7771 21.1773 23.7533 20.2011C24.7295 19.2249 25.2176 18.0463 25.2176 16.6654C25.2176 15.2844 24.7295 14.1058 23.7533 13.1297C22.7771 12.1535 21.5986 11.6654 20.2176 11.6654C18.8367 11.6654 17.6581 12.1535 16.6819 13.1297C15.7057 14.1058 15.2176 15.2844 15.2176 16.6654C15.2176 18.0463 15.7057 19.2249 16.6819 20.2011C17.6581 21.1773 18.8367 21.6654 20.2176 21.6654Z',
    style: `fill:${color}`,
  })

  iconElement.appendChild(pathElement)

  return iconElement
}

export const clusterRenderer = (map: google.maps.Map) => ({
  render: (cluster: Cluster) => {
    const className =
      'flex size-10 translate-y-1/2 scale-75 items-center justify-center rounded-full bg-[--marker-color] text-center text-lbody text-white'
    const content = document.createElement('div')
    content.innerText = cluster.count.toString()
    content.setAttribute('class', className)
    return new google.maps.marker.AdvancedMarkerElement({
      title: `${cluster.count} locations`,
      map,
      position: cluster.position,
      gmpDraggable: false,
      content,
    })
  },
})

export const createMarkers = (
  map: google.maps.Map,
  markerItems: MarkerItem[],
  onClick?: (item: MarkerItem) => void,
  color?: string
) =>
  markerItems.map((item) => {
    const marker = new google.maps.marker.AdvancedMarkerElement({
      map,
      title: item.name,
      position: new google.maps.LatLng(item.latitude, item.longitude),
      gmpDraggable: false,
      content: markerElement(item.name, item.isActive ? 'black' : color),
    })

    marker.addListener('click', () => {
      onClick?.(item)
    })

    return marker
  })

export const updateMapBounds = (
  map: google.maps.Map,
  markerItems: MarkerItem[] = [],
  mapOptions: google.maps.MapOptions
) => {
  const defaultLocation = mapOptions.center as { lat: number; lng: number }
  const items = markerItems.length ? markerItems : [{ latitude: defaultLocation.lat, longitude: defaultLocation.lng }]

  const lats = items.map(({ latitude }) => latitude)
  const longs = items.map(({ longitude }) => longitude)
  const zoomToMarker = items.length === 1 ? items[0] : items.find((marker: MarkerItem) => marker.isActive)

  if (zoomToMarker) {
    map.setZoom(17)
    map.setCenter({
      lat: Number(zoomToMarker.latitude),
      lng: Number(zoomToMarker.longitude),
    })
  } else {
    const southWest = new google.maps.LatLng(Math.min(...lats), Math.min(...longs))
    const northEast = new google.maps.LatLng(Math.max(...lats), Math.max(...longs))
    map.fitBounds(new google.maps.LatLngBounds(southWest, northEast))
  }
}
