import { assign, createMachine } from "xstate";
import * as services from "./services";

export interface JobSearchResultsContext {
  autocomplete: string[],
  query: string
}

export type JobSearchResultsEvents = 
  | { type: "UPDATE_QUERY", query: string }
  | { type: "STOP_AUTOCOMPLETE", query: string }
  | { type: "done.invoke.get-autocomplete", data: string[] }

export const machine = createMachine<JobSearchResultsContext, JobSearchResultsEvents>({
  predictableActionArguments: true,
  id: "job-search-results",
  initial: "idle",
  context: {
    autocomplete: [],
    query: ""
  },
  states: {
    idle: {
      on: {
        UPDATE_QUERY: {
          target: "getAutocomplete",
          cond: ({query}, evt) => {
            return evt.query != query
          }
        }
      },
      entry: assign({
        autocomplete: () => {
          return []
        },
        query: ({query}, evt) => {
          if (evt.type != "STOP_AUTOCOMPLETE")
            return query

          return evt.query
        }
      })
    },
    autocomplete: {
      on: {
        STOP_AUTOCOMPLETE: "idle",
        UPDATE_QUERY: "getAutocomplete"
      },
    },
    getAutocomplete: {
      on: {
        STOP_AUTOCOMPLETE: "idle",
        UPDATE_QUERY: "getAutocomplete"
      },
      entry: assign({
        query: ({query}, evt) => {
          if (evt.type != "UPDATE_QUERY")
            return query

          return evt.query
        }
      }),
      invoke: {
        id: "get-autocomplete",
        src: "getAutocomplete",
        onDone: {
          target: "autocomplete",
          actions: assign({
            autocomplete: ({autocomplete}, evt) => {
              if (evt.type != "done.invoke.get-autocomplete")
                return autocomplete
              return evt.data
            }
          })
        },
        onError: "autocomplete",
      },
    },
  },
},
{
  services 
});

export default machine