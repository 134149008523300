import { Children, useEffect, useState } from 'react'

import { trackScrollBar } from 'src/utils/tracking'
import { useScrollProgress } from 'src/utils/useScrollProgress'

function MapLocationList({ children }: { children: React.ReactNode }) {
  const [showOverlay, setShowOverlay] = useState(true)

  const scrollContainerRef = useScrollProgress(
    ({ milestone }) => {
      trackScrollBar('Reserve Unit Locator', milestone, 'vertical')
    },
    { axis: 'vertical', milestones: [0, 25, 50, 75, 100] }
  )

  useEffect(() => {
    if (!scrollContainerRef.current) return

    const el = scrollContainerRef.current

    const handleScroll = () => {
      setShowOverlay(el.scrollTop / (el.scrollHeight - el.clientHeight) < 1)
    }

    el.addEventListener('scroll', handleScroll)
    return () => {
      el.removeEventListener('scroll', handleScroll)
    }
  }, [scrollContainerRef])

  return (
    <>
      <div className="xsbody mt-6 pl-8 text-grey-medium md:pl-12 xm:pl-16 xl:pl-18 xxl:pl-[120px] [.page-tab_&]:pl-0">{`Showing: ${Children.count(children) ?? 0} locations`}</div>
      <div className="map-list-parent adf-scroll-bar relative overflow-y-scroll xm:mr-4" ref={scrollContainerRef}>
        <div className="flex flex-col">{children}</div>
      </div>
      {showOverlay && (
        <div className="pointer-events-none absolute bottom-0 left-0 right-5 z-30 h-22 bg-gradient-to-b from-transparent to-white" />
      )}
    </>
  )
}

export default MapLocationList
