import { CSSProperties, useEffect, useRef, useState } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

import { serviceColors, useService } from 'utils/useService'
import { getKey, getMapId, mapOptions, MapType } from './map.config'
import { clusterRenderer, createMarkers, MarkerItem, updateMapBounds } from './map.helpers'
import { DynamicLoader } from './DynamicLoader'

type MapProps = {
  mapType?: MapType
  markerItems?: MarkerItem[] | null
  userMarker?: MarkerItem
  onMarkerClicked?: (item: MarkerItem) => void
}

const Map = ({ mapType, markerItems, userMarker, onMarkerClicked }: MapProps): JSX.Element => {
  const [map, setMap] = useState<google.maps.Map | null>(null)

  const mapRef = useRef<HTMLDivElement>(null)
  const markersRef = useRef<google.maps.marker.AdvancedMarkerElement[] | null>(null)
  const centreMarkerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null)
  const markerClustererRef = useRef<MarkerClusterer | null>(null)
  const service = useService()
  const markerColor = serviceColors[service]
  const activeMarkerColor = '#ef4f32'

  // Load map and marker libraries, and initialise the map
  useEffect(() => {
    const apiKey = getKey(mapType)
    const mapId = getMapId(mapType)
    const options = {...mapOptions, mapId}

    if (!apiKey) return

    const loader = new DynamicLoader({
      apiKey,
      version: 'weekly',
      libraries: ['places'],
    })

    const libs = [loader.importLibrary('maps'), loader.importLibrary('marker')] as const
    Promise.all(libs)
      .then(([maps]) => {
        if (mapRef.current) {
          setMap(new maps.Map(mapRef.current, options))
        }
      })
      .catch((e) => {
        console.error('Error loading Google Maps:', e)
      })
  }, [mapType])

  // Refresh markers and cluster them
  useEffect(() => {
    if (!map) return

    const items = markerItems ?? []

    // Clear existing markers and clusterer
    try {
      markersRef.current?.forEach((marker) => (marker.map = null))
      markerClustererRef.current?.clearMarkers()

      // Create new markers and clusterer
      markersRef.current = createMarkers(map, items, onMarkerClicked)
      markerClustererRef.current = new MarkerClusterer({
        map,
        markers: markersRef.current,
        renderer: clusterRenderer(map),
      })

      // Create user marker if defined
      centreMarkerRef.current && (centreMarkerRef.current.map = null)
      if (userMarker) {
        centreMarkerRef.current = createMarkers(map, [userMarker], onMarkerClicked, activeMarkerColor)[0]
        items.push(userMarker)
      }

      updateMapBounds(map, items, mapOptions)
    } catch (error) {
      console.log(error)
    }
  }, [map, markerItems, onMarkerClicked, userMarker])

  return <div className="h-full" style={{ '--marker-color': markerColor } as CSSProperties} ref={mapRef} />
}

export default Map
