import React, { useEffect, useState } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

import Button from 'components/front-end/Buttons/Button'
import Drawer from 'components/front-end/Drawers/Drawer'
import Header from 'components/front-end/Drawers/drawer-components/Header'
import FormField from 'components/front-end/FormFields/FormField'
import Icon from 'components/front-end/Icons/Icon'
import { getBrowserLocation } from 'utils/BrowserLocation'
import type { LocationItem } from 'src/pages/api/v2/search/ClosestLocationLookup'

interface LocationDrawerProps {
  id: string
  service: string
  closeDrawer: () => void
  isOpen: boolean
  onLocationSet?: (data: UserLocationProps) => void
}

interface UserLocationProps {
  lat: number | undefined
  lng: number | undefined
  suburb: string
  state: string
  postcode: string
}

const LocationDrawer = (props: LocationDrawerProps) => {
  const { sitecoreContext } = useSitecoreContext()
  const [userLocation, setUserLocation] = useState<UserLocationProps>({
    lat: undefined,
    lng: undefined,
    suburb: '',
    state: '',
    postcode: '',
  })
  const [locationController, setLocationController] = useState(new AbortController())
  const [locationResults, setLocationResults] = useState<LocationItem[]>([])

  const [hasLocation, setHasLocation] = useState(false)
  const [geoError, setGeoError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isChangeLocation, setChangeLocation] = useState(false)
  // check if user location is in session storage and set to state
  useEffect(() => {
    const data = localStorage.getItem('userLocation') && JSON.parse(localStorage.getItem('userLocation') ?? '')
    setChangeLocation(false)
    if (data) {
      setUserLocation(data)
      setHasLocation(true)
    } else {
      setUserLocation({ lat: undefined, lng: undefined, suburb: '', state: '', postcode: '' })
      setHasLocation(false)
    }
  }, [props.isOpen])

  const handleLocationButton = (result: LocationItem) => {
    const data = {
      lat: result.latitude,
      lng: result.longitude,
      suburb: result.locality,
      state: result.state,
      postcode: result.postcode,
    }
    setUserLocation(data)
    localStorage.setItem('userLocation', JSON.stringify(data))
    setLocationResults([])
    setHasLocation(true)
    setChangeLocation(false)
  }

  const locationLookup = (value: string) => {
    const payload = {
      query: value?.toUpperCase(),
    }
    if (!value || sitecoreContext?.pageEditing == true) return

    try {
      locationController.abort()
    } catch (_ex) {}
    const newLocationController = new AbortController()
    setLocationController(newLocationController)
    fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/LocationLookup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((r: { items: LocationItem[] }) => {
        if ('items' in r && Array.isArray(r.items)) {
          setLocationResults(r.items)
        }
      })
  }

  const resetLocation = () => {
    setUserLocation({
      lat: undefined,
      lng: undefined,
      suburb: '',
      state: '',
      postcode: '',
    })
    localStorage.removeItem('userLocation')
    setHasLocation(false)
    setChangeLocation(true)
  }

  const drawerHeader = !isChangeLocation
    ? `${
        userLocation.suburb && userLocation.state
          ? userLocation.suburb + ' ' + userLocation.state + ' ' + userLocation.postcode
          : 'Location not set'
      } `
    : 'Change your location'

  const drawerCopy = !isChangeLocation
    ? 'We’ll be able to show you the closest events first if you share your location with us. Either select the button (if your location services are turned on) or enter your suburb or postcode.'
    : 'To see events closest to your location, either select the button (if your location services are turned on) or enter your suburb or postcode.'

  const searchHeader = !isChangeLocation
    ? 'Or search for a location, then set it:'
    : 'Or search for a location, then change to it:'

  const geoErrorMessage =
    geoError === 'User denied Geolocation'
      ? 'Please allow geolocation of browser then try again.'
      : 'We’re not able to detect your location. Try searching for it instead.'
  const overlayClass = `${
    isLoading
      ? 'block absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center z-10 bg-white bg-opacity-60'
      : 'hidden'
  }`
  const overlayIconClass = 'd-block svg-loading'
  const iconClass = 'd-block text-grey-light'
  return (
    <Drawer
      title="Location Settings"
      show={props.isOpen}
      closeDrawer={() => {
        if (hasLocation) {
          props?.onLocationSet?.(userLocation)
        }
        props.closeDrawer?.()
      }}
      hideFooter={true}
    >
      <div className={overlayClass}>
        {/* Loading indicator */}
        <div className={overlayIconClass}>
          <Icon name="chevron-right-double" type="stroke" width={120} height={120} className={iconClass} />
        </div>
      </div>
      <div className="light">
        <Header text={drawerHeader} />
        {geoError && <p className="mb-10 text-grey-medium">{geoErrorMessage}</p>}
        {!(hasLocation && !geoError) && <p className="mb-10 text-grey-medium">{drawerCopy}</p>}
        {!hasLocation ? (
          <>
            <Button
              data-trackingid={props.id}
              className="mb-4"
              button
              link={{ value: { text: 'Use my current location' } }}
              type="small-action"
              icon="my-location"
              service={props.service}
              onClick={() => {
                setIsLoading(true)
                getBrowserLocation(
                  (location) => {
                    if (location) {
                      setIsLoading(false)
                      setGeoError('')
                      const data = {
                        lat: location.latitude,
                        lng: location.longitude,
                        suburb: location.locality,
                        state: location.state,
                        postcode: location.postcode,
                      }
                      setUserLocation(data)
                      localStorage.setItem('userLocation', JSON.stringify(data))
                      setHasLocation(true)
                      setChangeLocation(false)
                    }
                  },
                  (msg) => {
                    setIsLoading(false)
                    setChangeLocation(false)
                    setGeoError(msg)
                  }
                )
              }}
            />
            <p className="my-6">{searchHeader}</p>
            <FormField
              type="text"
              placeholder="Start typing"
              label="Enter suburb or postcode"
              onChange={(value) => locationLookup(value as string)}
            />
            {locationResults.length > 0 && (
              <ul className="-mt-4 border border-grey-light ">
                {locationResults.map((result, i) => {
                  return (
                    <li key={i} className="bg-white even:bg-white-off">
                      <button
                        className="flex w-full items-center justify-between px-4 py-3"
                        onClick={() => handleLocationButton(result)}
                      >
                        <span>
                          {result.locality} {result.state} {result.postcode}, Australia
                        </span>
                        <Icon name="chevron-right-double" type="stroke" width={12} height={12} className="" />
                      </button>
                    </li>
                  )
                })}
              </ul>
            )}
          </>
        ) : (
          <Button
            data-trackingid={props.id}
            className="mt-4"
            button
            link={{ value: { text: userLocation.suburb ? 'Change my location' : 'Set location' } }}
            type="small-action"
            service={props.service}
            icon="my-location"
            onClick={() => resetLocation()}
          />
        )}
      </div>
    </Drawer>
  )
}

export default LocationDrawer
