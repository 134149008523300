import React from 'react'
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs'
import { replaceTextWithLink } from 'lib/replace-with-link'
import Link from 'next/link'
import { useService } from 'src/utils/useService'

import usePageContext from 'components/front-end/_layoutContext'
import Button, { ButtonProps } from 'components/front-end/Buttons/Button'
import Icon from 'components/front-end/Icons/Icon'

interface ContactCTAField {
  id: string
  Headline: string
  SubHeadline: string
  CTAButton1: LinkField
  CTAButton2: LinkField
  ContactIcon1: ButtonProps['icon']
  ContactTitle1: string
  ContactDetail1: string
  ContactIcon2: ButtonProps['icon']
  ContactTitle2: string
  ContactDetail2: string
  ContactIcon3: ButtonProps['icon']
  ContactTitle3: string
  ContactDetail3: string
  ContactIcon4: ButtonProps['icon']
  Contactlink4: LinkField
}

const ContactCTAComponent = ({
  id,
  Headline = '',
  SubHeadline = '',
  CTAButton1,
  CTAButton2,
  ContactIcon1,
  ContactTitle1 = '',
  ContactDetail1 = '',
  ContactIcon2,
  ContactTitle2 = '',
  ContactDetail2 = '',
  ContactIcon3,
  ContactTitle3 = '',
  ContactDetail3 = '',
  ContactIcon4,
  Contactlink4,
}: ContactCTAField): JSX.Element => {
  const state = usePageContext()
  const service = useService(state?.pageContext?.globalService, false)

  const contactCTAStyle = `w-full pt-22 h-full flex flex-col
   xxl:flex-row xl:flex-row lg:flex-row xm:flex-row md:flex-col sm:flex-col
  `
  const contactTileStyle = `border-grey-light border-t [.light_&]:border-grey-light [.dark_&]:border-grey-medium
  xxl:w-3/4 xxl:pr-22 xxl:pb-[50px] xxl:pt-[142px] xxl:pl-22 xxl:border-r
  xl:w-2/3 xl:p-18 xl:pt-[150px] xl:border-r
  lg:w-2/3 lg:p-16 lg:pt-[104px] lg:border-r
  xm:w-7/12 xm:p-16 xm:pt-[117px] xm:border-r
  md:p-12
  sm:p-10
   [@media(max-width:640px)]:p-5 [@media(max-width:640px)]:pt-10  [@media(max-width:1024px)]:border-b
  `

  const contactDetailStyle = `border-grey-light [.light_&]:border-grey-light [.dark_&]:border-grey-medium
    xxl:w-1/4   xxl:pr-22 xxl:pb-[50px] xxl:pt-[142px] xxl:border-t
    xl:w-1/3 xl:p-10 xl:pt-[142px] xl:border-t
    lg:w-1/3 lg:px-10 lg:pb-20 lg:pt-[89px] lg:border-t
    xm:w-5/12 xm:p-8 xm:pt-[87px] xm:border-t
    md:p-12 md:pt-8
    sm:p-10
     [@media(max-width:640px)]:p-5
    `

  const leftButton = `[@media(max-width:640px)]:mb-6`
  const contactSubHeadlineSTyle = `medium xxl:pt-8 xl:pt-8 lg:pt-[30px] xm:pt-6 md:pt-3.5 sm:pt-3.5 [@media(max-width:640px)]:pt-3.5`

  const contactCTASectionStyle = `pt-8 flex flex-col xxl:flex-row xl:flex-row lg:flex-row xm:flex-row md:flex-col sm:flex-col`
  const contactCTAButtonStyle = `mr-4 mb-4`

  const contactDetailLabel = `pt-8 pb-6 h-[76px] [@media(max-width:640px)]:pt-1 [@media(max-width:640px)]:h-11`

  const contactDetailSytle = `flex flex-row py-2 md:py-3`

  const contactDetailIcon = ` text-black-off dark:text-white`

  const contactDetailSection = `font-inter font-normal pl-4`

  const contactDetailTitle = ``

  return (
    <div data-span="12" className={`contentBlock ${contactCTAStyle}`}>
      <div className={contactTileStyle}>
        <h2 className="h1" data-span="6">
          {Headline}
        </h2>
        <div className={`${contactSubHeadlineSTyle}`}>{SubHeadline}</div>
        <ul className={contactCTASectionStyle}>
          {CTAButton1?.value.href && (
            <li>
              <Button
                data-trackingid={id}
                type="primary"
                className={`${contactCTAButtonStyle} ${leftButton}`}
                link={CTAButton1}
                icon="chevron-right-double"
                service={service}
              />
            </li>
          )}
          {CTAButton2?.value.href && (
            <li>
              <Button
                data-trackingid={id}
                type="secondary"
                className={contactCTAButtonStyle}
                link={CTAButton2}
                icon="chevron-right-double"
                service={service}
              />
            </li>
          )}
        </ul>
      </div>

      <div className={contactDetailStyle}>
        <div className={`ltag ${contactDetailLabel}`}>Contact Details</div>
        <div className={contactDetailSytle}>
          {ContactIcon1 ? <Icon name={ContactIcon1} type="stroke" className={contactDetailIcon} /> : null}
          <div className={`${contactDetailSection}`}>
            <div className={`mbody-bold text-black-off dark:text-white ${contactDetailTitle}`}>{ContactTitle1}</div>
            <div className={`sbody`}>{ContactDetail1}</div>
          </div>
        </div>
        <div className={contactDetailSytle}>
          {ContactIcon2 ? <Icon name={ContactIcon2} type="stroke" className={contactDetailIcon} /> : null}
          <div className={`${contactDetailSection}`}>
            <div className={`mbody-bold text-black-off dark:text-white ${contactDetailTitle}`}>{ContactTitle2}</div>
            <div className={`sbody`}>{ContactDetail2}</div>
          </div>
        </div>
        <div className={contactDetailSytle}>
          {ContactIcon3 ? <Icon name={ContactIcon3} type="stroke" className={contactDetailIcon} /> : null}
          <div className={`${contactDetailSection}`}>
            <div className={`mbody-bold text-black-off dark:text-white ${contactDetailTitle}`}>{ContactTitle3}</div>
            <div className={`sbody [&_a_]:link-underline [.dark_&]:text-white`}>
              {replaceTextWithLink(ContactDetail3, 'Call +61 3 9267 6101', 'tel:+61 3 9267 6101')}
            </div>
          </div>
        </div>
        <div className={contactDetailSytle}>
          {ContactIcon4 ? <Icon name={ContactIcon4} type="stroke" className={contactDetailIcon} /> : null}
          <div className={`${contactDetailSection}`}>
            <Link
              className={`${service ? service : ''} link-underline mbody-bold text-black-off dark:text-white`}
              href={Contactlink4?.value?.href as string}
              target={Contactlink4?.value?.target}
            >
              {Contactlink4?.value?.text}{' '}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactCTAComponent
