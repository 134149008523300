import { useEffect, useMemo } from 'react'

import { NavigationContext } from 'components/front-end/Header/NavigationProvider'
import SiteNavigation from 'components/front-end/Header/SiteNavigation'
import { NavigationProps } from 'components/front-end/Navigation/_interface'
import { parseNavigationFields } from './front-end/Header/navigationHelpers'

const ADFNavigation = ({ fields }: NavigationProps): JSX.Element => {
  const navigationFields = fields.data.item.children.results
  const data = useMemo(() => parseNavigationFields(navigationFields), [navigationFields])
  const { send } = NavigationContext.useActorRef()

  useEffect(() => {
    send({ type: 'SET_NAVIGATION_DATA', data })
  }, [data, send])

  return (
    <>
      <SiteNavigation actionFields={fields.data.datasource}>
        <div className="profile-tab-desktop border-none [&:empty]:hidden" />
      </SiteNavigation>

      <style jsx global>{`
        :root {
          --navbar-height: 64px;
        }
        @media (min-width: 1024px) {
          :root {
            --navbar-height: 64px;
          }
        }
        @media (min-width: 1440px) {
          :root {
            --navbar-height: 88px;
          }
        }
      `}</style>
    </>
  )
}

export default ADFNavigation

