import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'
import { twJoin } from 'tailwind-merge'

import Button from 'components/front-end/Buttons/Button'
import Icon from 'components/front-end/Icons/Icon'
import ImageLoader from 'components/front-end/Images/ImageLoader'
import GoogleMap from 'components/front-end/Map/GoogleMap'
import { MapType } from 'components/front-end/Map/map.config'
import { MarkerItem } from 'components/front-end/Map/map.helpers'
import MapLocationList from 'components/front-end/Map/MapLocationList'
import MapSearchField from 'components/front-end/Map/MapSearchField'
import { ComponentProps } from 'lib/component-props'
import { getBrowserLocation } from 'utils/BrowserLocation'
import { track } from 'utils/tracking'
import { LocationItem, LocationResponse } from 'src/pages/api/v2/search/ClosestLocationLookup'

export interface MapUnit extends MarkerItem {
  description: string
  suburb: string
  state: string
  postcode: string
  nameExtra: string
  serviceName: string
  typeName: string
  address: string
  phoneNumber: string
  openingHours: string
  image: string
  postalAddress?: string
  postalState?: string
  postalSuburb?: string
  postalPostcode?: string
  distance: number
}

interface MapUnitResponse {
  TotalCount: number
  Results: MapUnit[]
  Message: string | null
}

export type MapUnitLocatorProps = ComponentProps & {
  fields: {
    data: {
      datasource: {
        preset: {
          targetItem: {
            id: string
          }
        }
        showSearch: Field<boolean>
        showDirections: Field<boolean>
        showImages: Field<boolean>
        mapType: Field<MapType>
      }
    }
  }
}

const ADFMapUnitLocator = ({ fields, rendering }: MapUnitLocatorProps): JSX.Element => {
  const datasource = fields?.data?.datasource
  const showSearch = Boolean(datasource?.showSearch?.value)
  const showDirections = Boolean(datasource.showDirections?.value)
  const showImages = Boolean(datasource.showImages?.value)
  const mapType = fields?.data?.datasource?.mapType?.value

  const searchId = datasource.preset?.targetItem?.id ?? 'e6e349b3-c84e-4027-98df-65294c964d0b'

  const { sitecoreContext } = useSitecoreContext()

  const [location, setLocation] = useState<LocationResponse | null>(null)
  const [userLocation, setUserLocation] = useState<LocationItem | null>(null)
  const [mapUnits, setMapUnits] = useState<MapUnitResponse['Results'] | null>(null)
  const [autocomplete, setAutocomplete] = useState<string[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const unitListRef = React.createRef<HTMLDivElement>()

  const locationsLoaderRef = useRef<AbortController | undefined>()
  const unitLoaderRef = useRef<AbortController | undefined>()

  const setFocusedUnit = (focusedUnit: MapUnit) => {
    setMapUnits((units) => units?.map((unit) => ({ ...unit, isActive: unit === focusedUnit })) ?? [])
    unitListRef.current
      ?.querySelector<HTMLButtonElement>(
        `[data-location="${focusedUnit.suburb}${focusedUnit.state}${focusedUnit.postcode}"]`
      )
      ?.focus()
  }

  // LOAD LOCATIONS FOR AUTOCOMPLETION
  const handleUpdateQuery = useCallback(
    (q: string) => {
      if (!q || sitecoreContext?.pageEditing) {
        return
      }

      const abortController = locationsLoaderRef.current
      if (abortController && !abortController.signal.aborted) {
        abortController.abort('Updated query before previous locations search completed.')
      }

      locationsLoaderRef.current = new AbortController()

      try {
        loadLocations(q.toUpperCase(), locationsLoaderRef.current.signal).then((locationResponse) => {
          if (locationResponse && !locationsLoaderRef.current?.signal.aborted) {
            setLocation(locationResponse)
            setAutocomplete(locationResponse.items?.map(locationItemToString))
          }
        })
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          return
        }
        console.error('Fetch error:', error)
      }
    },
    [sitecoreContext?.pageEditing]
  )

  // LOAD UNITS LOCATIONS FOR MAP
  const updateUserLocation = useCallback(
    (loc: LocationItem | null) => {
      const abortController = unitLoaderRef.current
      if (abortController && !abortController.signal.aborted) {
        abortController.abort('Updated location before previous unit search completed.')
      }

      unitLoaderRef.current = new AbortController()
      setIsLoading(true)
      setUserLocation(loc)

      try {
        loadUnits(userLocation, searchId, unitLoaderRef.current.signal).then((unitsResponse) => {
          if (unitsResponse && !unitLoaderRef.current?.signal.aborted) {
            setMapUnits(
              unitsResponse.Results.filter(({ suburb, state, postcode }) => Boolean(suburb && state && postcode)) ?? []
            )
            setIsLoading(false)
          }
        })
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          return
        }
        console.error('Fetch error:', error)
      }
    },
    [searchId, userLocation]
  )

  useEffect(() => {
    updateUserLocation(null)
    handleUpdateQuery('')
  }, [handleUpdateQuery, updateUserLocation])

  const handleUseLocation = () => {
    setIsLoading(true)
    getBrowserLocation(
      (location: LocationItem) => {
        setIsLoading(false)
        updateUserLocation(location)
      },
      (msg) => {
        setIsLoading(false)
        console.error(msg)
      }
    )
  }

  const handleClickReset = () => {
    setAutocomplete(undefined)
    updateUserLocation(null)
  }

  const handleClickAutocomplete = (locationQuery: string) => {
    const newLocationItem = location?.items?.find((item) => locationItemToString(item) === locationQuery) || null
    setAutocomplete(undefined)
    updateUserLocation(newLocationItem)
  }

  const locationParams = ({ address, suburb, state, postcode }: MapUnit) =>
    [address, suburb, state, postcode].filter(Boolean).join(' ')

  return (
    <div className="content-inner">
      <section
        className={twJoin(
          'SECTION-MAP relative flex h-screen max-h-[calc(100vh_-_280px)] border-y border-grey-light',
          isLoading && 'h-[200px] bg-white'
        )}
      >
        <div
          ref={unitListRef}
          className={twJoin(
            'map-list adf-scroll-bar-container relative flex w-full flex-col transition duration-500 xm:w-5/12 xl:w-1/3',
            isLoading && 'opacity-0'
          )}
        >
          {showSearch && (
            <div className="row">
              <MapSearchField
                searchTitle="Find a reserve unit near you"
                placeholderText="Enter postcode or suburb"
                routeQuery={userLocation ? locationItemToString(userLocation) : ''}
                inPage
                location="My location"
                isJobSearch
                updateResults={handleUpdateQuery}
                queryList={{
                  TotalCount: autocomplete?.length ?? 0,
                  Results: autocomplete,
                  Message: null,
                }}
                onLocationClick={handleUseLocation}
                onQueryClick={handleClickAutocomplete}
                onResetClick={handleClickReset}
              />
            </div>
          )}
          <MapLocationList>
            {mapUnits?.map((unit, i) => (
              <div
                key={`${unit.id}-${i}`}
                className={twJoin(
                  'flex flex-row justify-between gap-7 border-b border-grey-light bg-white pb-4 pl-8 pr-6 pt-4 md:pl-12 xm:pb-8 xm:pl-16 xm:pt-8 xl:pl-18 xxl:pl-[120px] [.page-tab_&]:pl-0',
                  unit.isActive && 'active bg-white-off'
                )}
              >
                <div>
                  <div className="mbody-bold mb-1.5">{unit.name}</div>
                  <div className="mb-3 flex flex-row">
                    <div>
                      <div className="flex flex-nowrap">
                        <Icon name="location" width={12} height={12} className="mr-1 mt-0.5" />
                        <span className="xsbody block text-grey-medium">{locationParams(unit)}</span>
                      </div>
                    </div>
                  </div>

                  {unit.postalAddress && unit.postalState && unit.postalSuburb && unit.postalPostcode && (
                    <div className="-mt-2  flex flex-row">
                      <div>
                        <div className="flex flex-nowrap">
                          <Icon name="email" type="stroke" width={12} height={12} className="mr-1 mt-0.5" />
                          <span className="xsbody block text-grey-medium">
                            {`${unit.postalAddress ? unit.postalAddress + ' ' : ''}${unit.postalSuburb} ${unit.postalState} ${
                              unit.postalPostcode
                            }`}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {unit.openingHours && (
                    <div className="mb-3 mt-1 flex flex-nowrap">
                      <Icon name="clock" width={12} height={12} className="mr-1 mt-0.5" type="stroke" />
                      <span className="xsbody block text-grey-medium">{unit.openingHours}</span>
                    </div>
                  )}
                  {unit.description && <div className="xsbody mb-4 hidden xm:block">{unit.description}</div>}
                  <div className="flex flex-nowrap gap-3">
                    {showDirections && (
                      <div className="hidden xm:block">
                        <Button
                          data-trackingid={rendering.uid as string}
                          link={{
                            value: {
                              href: `https://www.google.com/maps?q=${locationParams(unit)}`,
                              target: '_blank',
                              text: 'Get Directions',
                            },
                          }}
                          icon="directions"
                          type="tertiary-action"
                          onClick={() => {
                            track({
                              event: 'map',
                              map_click: `${unit.name} - Get Directions`,
                            })
                          }}
                        />
                      </div>
                    )}
                    <div className="hidden xm:block">
                      <Button
                        data-trackingid={rendering.uid as string}
                        data-location={[unit.suburb, unit.state, unit.postcode].join('')}
                        className={`p${unit.id.replaceAll('-', '')}`}
                        link={{
                          value: {
                            href: `https://www.google.com/maps?q=${unit.latitude},${unit.longitude}`,
                            target: '_blank',
                            text: 'View on map',
                          },
                        }}
                        onClick={(event) => {
                          event?.preventDefault()
                          // trigger refresh as the reference is different
                          setFocusedUnit(unit)
                          track({
                            event: 'map',
                            map_click: `${unit.name} - View on map`,
                          })
                        }}
                        icon="location"
                        type="tertiary-action"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center">
                  {showImages && unit.image && (
                    <div className="hidden min-h-[103px] min-w-[134px] xm:block">
                      <ImageLoader
                        width={134}
                        height={103}
                        src={`${process.env.HUB_API_HOST}${unit.image}`}
                        alt={unit.name}
                        lazy
                      />
                    </div>
                  )}
                  <Link
                    className="block xm:hidden"
                    href={`https://www.google.com/maps?q=${`${unit.address ? unit.address + ' ' : ''}${unit.suburb} ${
                      unit.state
                    } ${unit.postcode}`}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={() => {
                      track({
                        event: 'map',
                        map_click: `${unit.name} - View on map`,
                      })
                    }}
                  >
                    <Icon name="arrow-east" type="stroke" width={20} height={20} />
                  </Link>
                </div>
              </div>
            ))}
          </MapLocationList>
        </div>
        <div className={twJoin('hidden transition duration-500 xm:block xm:w-2/3', isLoading && 'opacity-0')}>
          <GoogleMap
            mapType={mapType}
            userMarker={
              userLocation
                ? {
                    latitude: userLocation.latitude,
                    longitude: userLocation.longitude,
                    id: 'centreMarker',
                    name: locationItemToString(userLocation),
                  }
                : undefined
            }
            markerItems={mapUnits}
            onMarkerClicked={(marker: MapUnit) => {
              setFocusedUnit(marker)
            }}
          />
        </div>
        {isLoading && (
          <span className="absolute bottom-0 top-0 flex w-full items-center justify-center">
            <div className="svg-loading">
              <Icon name="chevron-right-double" type="stroke" width={120} height={120} className="text-grey-light" />
            </div>
          </span>
        )}
      </section>
    </div>
  )
}

export const locationItemToString = ({ locality, state, postcode }: LocationItem) => `${locality} ${state} ${postcode}`

export async function loadLocations(query: string, signal: AbortSignal): Promise<LocationResponse | void> {
  return fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/LocationLookup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
    }),
    signal,
  })
    .then((r): Promise<LocationResponse> => r.json())
    .catch((e) => {
      console.error(e)
    })
}

async function loadUnits(userLocation: LocationItem | null, searchId: string, signal: AbortSignal) {
  return fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/LocationSearch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filterValues: [],
      searchId,
      page: 1,
      perPage: userLocation?.latitude ? 5 : 500,
      latitude: userLocation?.latitude,
      longitude: userLocation?.longitude,
    }),

    signal,
  })
    .then((r): Promise<MapUnitResponse> => r.json())
    .catch((e) => {
      console.error(e)
    })
}

export default ADFMapUnitLocator
