import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'
import { twJoin } from 'tailwind-merge'

import Button from 'components/front-end/Buttons/Button'
import Icon from 'components/front-end/Icons/Icon'
import GoogleMap from 'components/front-end/Map/GoogleMap'
import MapDrawer from 'components/front-end/Map/MapJobDrawer'
import MapSearchField from 'components/front-end/Map/MapSearchField'
import { ComponentProps } from 'lib/component-props'
import { getBrowserLocation } from 'src/utils/BrowserLocation'
import { track } from 'src/utils/tracking'
import { LocationItem, LocationResponse } from 'src/pages/api/v2/search/ClosestLocationLookup'
import { loadLocations, locationItemToString } from './ADFMapUnitLocator'
import { SearchResult } from './ADFSearchResults'
import type { MarkerItem } from './front-end/Map/map.helpers'
import MapLocationList from './front-end/Map/MapLocationList'

export interface ReserveUnit extends MarkerItem {
  distance: number
  suburb: string
  state: string
  postcode: string
  jobs: SearchResult[]
}

interface ReserveResponse {
  units?: ReserveUnit[]
}

const ADFReserveUnitLocator = (props: ComponentProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  const [location, setLocation] = useState<LocationResponse | null>(null)
  const [userLocation, setUserLocation] = useState<LocationItem | null>(null)
  const [reserveUnits, setReserveUnits] = useState<ReserveResponse['units'] | null>(null)
  const [autocomplete, setAutocomplete] = useState<string[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [isMapDrawer, setShowMapDrawer] = useState(false)
  const [jobsUnit, setJobsUnit] = useState<ReserveUnit | null>(null)
  const unitListRef = React.createRef<HTMLDivElement>()

  const locationsLoaderRef = useRef<AbortController | undefined>()
  const unitLoaderRef = useRef<AbortController | undefined>()

  const setFocusedUnit = (focusedUnit: ReserveUnit) => {
    setReserveUnits((units) => units?.map((unit) => ({ ...unit, isActive: unit === focusedUnit })) ?? [])
    unitListRef.current
      ?.querySelector<HTMLButtonElement>(
        `[data-location="${focusedUnit.suburb}${focusedUnit.state}${focusedUnit.postcode}"]`
      )
      ?.focus()
  }

  // LOAD LOCATIONS FOR AUTOCOMPLETION
  const handleUpdateQuery = useCallback(
    (q: string) => {
      if (!q || sitecoreContext?.pageEditing) {
        return
      }

      const abortController = locationsLoaderRef.current
      if (abortController && !abortController.signal.aborted) {
        abortController.abort('Updated query before previous locations search completed.')
      }

      locationsLoaderRef.current = new AbortController()

      try {
        loadLocations(q.toUpperCase(), locationsLoaderRef.current.signal).then((locationResponse) => {
          if (locationResponse && !locationsLoaderRef.current?.signal.aborted) {
            setLocation(locationResponse)
            setAutocomplete(locationResponse.items?.map(locationItemToString))
          }
        })
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          return
        }
        console.error('Fetch error:', error)
      }
    },
    [sitecoreContext?.pageEditing]
  )

  // LOAD UNITS LOCATIONS FOR MAP
  const updateUserLocation = useCallback((loc: LocationItem | null) => {
    const abortController = unitLoaderRef.current
    if (abortController && !abortController.signal.aborted) {
      abortController.abort('Updated location before previous unit search completed.')
    }

    unitLoaderRef.current = new AbortController()
    setIsLoading(true)
    setUserLocation(loc)

    try {
      loadReserveUnits(loc, unitLoaderRef.current.signal).then((reserveUnitsResponse) => {
        if (reserveUnitsResponse && !unitLoaderRef.current?.signal.aborted) {
          setReserveUnits(reserveUnitsResponse.units ?? [])
          setIsLoading(false)
        }
      })
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        return
      }
      console.error('Fetch error:', error)
    }
  }, [])

  useEffect(() => {
    updateUserLocation(null)
    handleUpdateQuery('')
  }, [handleUpdateQuery, updateUserLocation])

  const handleUseLocation = () => {
    setIsLoading(true)
    getBrowserLocation(
      (location: LocationItem) => {
        setIsLoading(false)
        updateUserLocation(location)
      },
      (msg) => {
        setIsLoading(false)
        console.error(msg)
      }
    )
  }

  const handleClickReset = () => {
    setAutocomplete(undefined)
    updateUserLocation(null)
  }

  const handleClickAutocomplete = (locationQuery: string) => {
    const newLocationItem = location?.items?.find((item) => locationItemToString(item) === locationQuery) || null
    setAutocomplete(undefined)
    updateUserLocation(newLocationItem)
  }

  return (
    <div className="content-inner">
      <section
        className={twJoin(
          'SECTION-MAP relative flex max-h-[calc(100vh_-_280px)] border-y border-grey-light',
          isLoading && 'h-[200px] bg-white'
        )}
      >
        <div
          ref={unitListRef}
          className={twJoin(
            'map-list adf-scroll-bar-container relative flex w-full flex-col transition duration-500 xm:w-5/12 xl:w-1/3',
            isLoading && 'opacity-0'
          )}
        >
          <div className="row">
            <MapSearchField
              searchTitle="Find a reserve unit near you"
              placeholderText="Enter postcode or suburb"
              routeQuery={userLocation ? locationItemToString(userLocation) : ''}
              inPage
              location="My location"
              isJobSearch
              updateResults={handleUpdateQuery}
              queryList={{
                TotalCount: autocomplete?.length ?? 0,
                Results: autocomplete,
                Message: null,
              }}
              onLocationClick={handleUseLocation}
              onQueryClick={handleClickAutocomplete}
              onResetClick={handleClickReset}
            />
          </div>
          <MapLocationList>
            {reserveUnits
              ?.filter((unit) => !!unit && 'jobs' in unit)
              .map((unit, i) => {
                const numberOfJobs = unit.jobs?.length ?? 0
                const jobsButtonLabel = `${numberOfJobs} ${numberOfJobs === 1 ? 'role' : 'roles'} available`
                return (
                  <div
                    key={`${unit.id}-${i}`}
                    className={`${unit.isActive ? 'active bg-white-off' : 'bg-white'} border-b border-grey-light pb-4 pl-8 pr-6 pt-4 md:pl-12 xm:pb-8 xm:pl-16 xm:pt-8 xl:pl-18 xxl:pl-[120px] [.page-tab_&]:pl-0`}
                  >
                    <button
                      onClick={(event) => {
                        setFocusedUnit(unit)
                        track({
                          event: 'map',
                          map_click: `${unit.name} - ${event.currentTarget.textContent}`,
                        })
                      }}
                      className="mbody-bold mb-1.5 text-left hover:text-black hover:underline"
                    >
                      {unit.name}
                    </button>
                    <div className="mb-[20.5px] flex flex-row">
                      <Icon name="location" width={12} height={12} className="mr-1 mt-0.5" />
                      <button
                        className="xsbody hidden text-left underline hover:text-black xm:block"
                        data-location={`${unit.suburb}${unit.state}${unit.postcode}`}
                        onClick={(event) => {
                          setFocusedUnit(unit)
                          track({
                            event: 'map',
                            map_click: `${unit.name} - ${event.currentTarget.textContent}`,
                          })
                        }}
                      >
                        <span>{`${unit.suburb} ${unit.state} ${unit.postcode}`}</span>
                      </button>
                      <span className="xsbody ml-1 hidden xm:inline">
                        {' • '}
                        {unit.distance !== 0 && `${unit.distance}km away  • `}
                      </span>
                      <Link
                        className="xsbody ml-1 shrink-0 underline hover:text-black"
                        href={`https://www.google.com/maps?q=${`${unit.latitude},${unit.longitude}`}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          track({
                            event: 'map',
                            map_click: `${unit.name} - Get Directions`,
                          })
                        }}
                      >
                        <span>Get Directions</span>
                      </Link>
                    </div>

                    {numberOfJobs === 0 ? (
                      <>
                        <div className="button">{jobsButtonLabel}</div>
                        <div className="xsbody mt-2 text-grey-medium">
                          Call 13 19 01 to find a flexible part time role near you.
                        </div>
                      </>
                    ) : (
                      <Button
                        data-trackingid={props.rendering.uid as string}
                        onClick={() => {
                          setJobsUnit(unit)
                          setShowMapDrawer(true)
                          track({
                            event: 'map',
                            map_click: `${unit.name} - ${jobsButtonLabel}`,
                          })
                        }}
                        link={{
                          value: {
                            text: jobsButtonLabel,
                          },
                        }}
                        button
                        icon="arrow-east"
                        type="tertiary"
                      />
                    )}
                  </div>
                )
              })}
          </MapLocationList>
        </div>
        <div className={twJoin('hidden transition duration-500 xm:block xm:w-2/3', isLoading && 'opacity-0')}>
          <GoogleMap
            mapType="army-reserve"
            userMarker={
              userLocation
                ? {
                    latitude: userLocation.latitude,
                    longitude: userLocation.longitude,
                    id: 'centreMarker',
                    name: locationItemToString(userLocation),
                  }
                : undefined
            }
            markerItems={reserveUnits}
            onMarkerClicked={(marker: ReserveUnit) => {
              setFocusedUnit(marker)
            }}
          ></GoogleMap>
        </div>
        {isLoading && (
          <span className="absolute bottom-0 top-0 flex w-full items-center justify-center">
            <div className="svg-loading">
              <Icon name="chevron-right-double" type="stroke" width={120} height={120} className="text-grey-light" />
            </div>
          </span>
        )}
      </section>
      <MapDrawer
        props={jobsUnit}
        id={props.rendering.uid as string}
        isOpen={isMapDrawer}
        onClose={() => setShowMapDrawer(false)}
      />
    </div>
  )
}

async function loadReserveUnits(userLocation: LocationItem | null, signal: AbortSignal) {
  return fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/ReserveUnitLocations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      latitude: userLocation?.latitude,
      longitude: userLocation?.longitude,
    }),
    signal,
  })
    .then((r): Promise<ReserveResponse> => r.json())
    .catch((e) => {
      console.error(e)
    })
}

export default ADFReserveUnitLocator
