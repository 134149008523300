import { ParsedUrlQuery } from 'querystring'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  ComponentParams,
  ComponentRendering,
  Field,
  Placeholder,
  PlaceholdersData,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs'
import { useMachine } from '@xstate/react'
import { useRouter } from 'next/router'

import { KeywordSuggestionsRequest, KeywordSuggestionsResponse } from 'components/ADFEventSearchResults'
import usePageContext from 'components/front-end/_layoutContext'
import Button from 'components/front-end/Buttons/Button'
import Pagination from 'components/front-end/Buttons/Pagination'
import CustomSelect from 'components/front-end/CustomSelect/CustomSelect'
import { FilterValue } from 'components/front-end/Drawers/drawer-components/Filter'
import Icon from 'components/front-end/Icons/Icon'
import JobCard from 'components/front-end/JobCard/JobCard'
import SearchDrawer from 'components/front-end/Search/SearchDrawer'
import SearchField from 'components/front-end/Search/SearchField'
import SearchNoResults from 'components/front-end/Search/SearchNoResults'
import FilterTag from 'components/front-end/Tags/FilterTag'
import { useIntersect } from 'utils/intersection'
import { track, trackFilterInteraction } from 'utils/tracking'
import JobSearchResultsMachine from 'src/machines/JobSearchResultsMachine'

export interface SearchResult {
  interests?: string[] | null
  entryMethod?: string[] | null
  entryMethods?: string[] | null
  serviceId?: string
  score?: number
  closureDate?: string | null
  applyUrl?: string | null
  id?: string | null
  title?: string | null
  url?: string | null
  image?: string | string[]
  service?: string | undefined
  cssClass?: string | undefined
  copy?: string | null
  isSaved?: boolean
  isClosedText?: string | null
  isPriorityText?: string | null
  isPriority?: boolean
  priorityRole?: string | null
  priorityOrder?: number
  isReserve?: boolean
  isFulltime?: boolean
  isOfficer?: boolean | null
  isClosed?: boolean
  isGapYear?: boolean
  rightButtonText: string | null
  unsavedButtonText: string | null
  savedButtonText: string | null
  cardType?: string
  pathways?: {
    title: string
    description: string
    service: string
    link: string
    jobs: {
      name: string
      url: string
    }[]
  }[]
}

export interface SearchResultsResponse {
  TotalCount: number
  Results: SearchResult[]
  Message: string | null
}

export interface SearchResultsRequest {
  filterValues: {
    FacetId: string
    FacetValues: string[]
  }[]
  searchId: string
  page: number
  perPage: number
  query?: string
}

export type OrderingType = {
  id: string
  name: Field<string>
  fieldName: Field<string>
  isDefault: Field<boolean>
  description: Field<string>
}

export type SearchResultsDataType = {
  isReserve: Field<{ value: boolean }>
  orderingHeading: Field<string>
  orderingOptions: {
    id: string
    name: string
    targetItems: Array<OrderingType>
  }
  typeHeading: Field<string>
  serviceHeading: Field<string>
  entryMethodHeading: Field<string>
  generalEntryMethodHeading: Field<string>
  generalEntryMethodDescription: Field<string>
  officerEntryMethodHeading: Field<string>
  officerEntryMethodDescription: Field<string>
  gapYearEntryMethodHeading: Field<string>
  gapYearEntryMethodDescription: Field<string>
  categoryHeading: Field<string>
  preSelectedCategory: {
    targetItems: Array<selectedType>
  }
  hideSearchBar: Field<boolean>
  resultsCount: Field<{ value: number }>
  showDegrees: Field<boolean>
  showAutoComplete: Field<boolean>
  preSelectedEntryMethod: {
    targetItems: Array<selectedType>
  }
  degreeSearchTitle: Field<string>
  degreeSearchPlaceholder: Field<string>
}

export type selectedType = {
  name: Field<string>
}

export type FacetType = {
  name: Field<string>
  displayName: Field<string>
  id: string
  groupingName: {
    targetItem: {
      name: string
    }
  }
}

export interface DataField {
  id: string
  name: Field<string>
  children: {
    results: FacetType[]
  }
}

interface Fields {
  data: {
    datasource: SearchResultsDataType
    services: DataField
    jobTypes: DataField
    priorityOrders: DataField
    entryMethods: DataField
    reserveEntryMethods: DataField
    allEntryMethods: DataField
    categories: DataField
    degrees: DataField
  }
}

export interface ADFSearchResultsProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: Fields
  placeholders?: PlaceholdersData
  results: SearchResultsResponse
}

const HeadingKeysMap = {
  //OrderingOptions: 'orderingHeading',
  PriorityOrder: 'orderingHeading',
  categories: 'categoryHeading',
  entrymethods: 'entryMethodHeading',
  isfulltime: 'typeHeading',
  serviceid: 'serviceHeading',
} as const

type HeadingKeyType = keyof typeof HeadingKeysMap
type HeadingKeys = (typeof HeadingKeysMap)[HeadingKeyType]

const reservesQueryValue = 'reserves'
const fulltimeQueryValue = 'full-time'

function focusInView() {
  window?.scrollTo({ top: 0, behavior: 'smooth' })
}

const ADFSearchResults = (props: ADFSearchResultsProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  const [state, send] = useMachine(JobSearchResultsMachine)
  const { query, autocomplete } = state.context

  const pageState = usePageContext().pageContext
  const router = useRouter()

  const typesProps = props?.fields?.data?.jobTypes
  const priorityOrderProps = props?.fields?.data?.priorityOrders
  const [isReserve, setIsReserve] = useState<boolean>(() => {
    const reservesRegexp = `(${typesProps?.name?.value}=${reservesQueryValue})`
    const isReserveMatches = router?.asPath?.match(reservesRegexp)
    return !!isReserveMatches?.[1]
  })

  const [isFulltime, setIsFulltime] = useState<boolean>(() => {
    const fulltimeRegexp = `(${typesProps?.name?.value}=${fulltimeQueryValue})`
    const isFulltimeMatches = router?.asPath?.match(fulltimeRegexp)
    return !!isFulltimeMatches?.[1]
  })

  //short cuts
  const orderingProps = props?.fields?.data?.datasource?.orderingOptions
  const servicesProps = props?.fields?.data?.services
  const reserveEntryMethodsProps = props?.fields?.data?.reserveEntryMethods
  const generalEntryMethodsProps = props?.fields?.data?.entryMethods
  const allEntryMethodsProps = props?.fields?.data?.allEntryMethods
  const entryMethodsProps = isReserve
    ? props?.fields?.data?.reserveEntryMethods
    : isFulltime
      ? props?.fields?.data?.entryMethods
      : allEntryMethodsProps
  const categoriesProps = props?.fields?.data?.categories
  //get preselected category from datasource
  const preSelectedCategoryItems = props?.fields?.data?.datasource?.preSelectedCategory?.targetItems?.map(
    (item) => item?.name?.value
  )

  const preSelectedEntryMethods = props?.fields?.data?.datasource?.preSelectedEntryMethod?.targetItems?.map(
    (item) => item?.name?.value
  )
  const hideSearchBar = props?.fields?.data?.datasource?.hideSearchBar?.value ?? false
  const resultsCount = props?.fields?.data?.datasource?.resultsCount?.value?.value ?? 6
  const perPageCount = hideSearchBar ? resultsCount : 10
  const showDegrees = props?.fields?.data?.datasource?.showDegrees?.value ?? false
  const showAutoComplete = props?.fields?.data?.datasource?.showAutoComplete?.value ?? false
  const degreeProps = props?.fields?.data?.degrees
  const degreeSearchTitle = props?.fields?.data?.datasource?.degreeSearchTitle?.value ?? 'Search'
  const degreeSearchPlaceholder = props?.fields?.data?.datasource?.degreeSearchPlaceholder?.value ?? 'Start Typing'

  const degreeSearchId = 'ac546cc6-3699-434f-8991-94b05d9972b0'
  const jobSearchId = 'efc96bb1-53f2-42aa-b8eb-ca9ad7a1596a'

  const [currentPage, setCurrentPage] = useState<number>(() => {
    const matches = router?.asPath?.match(/page=([^&]*)/)
    const page = matches?.[1]
    return page && page != '' ? parseInt(page) : 1
  })
  const [resultsPerPage] = useState<number>(() => {
    const matches = router?.asPath?.match(/perPage=([^&]*)/)
    const perPage = matches?.[1]
    return perPage && perPage != '' ? parseInt(perPage) : 10
  })
  const phKey = `container-${props.params?.DynamicPlaceholderId}`
  // const gridcols = props.params.gridcols as string
  const scrollOffset = 0 // scroll offset for pagination click
  // const [scrollOffset, setScrollOffset] = useState(0) // scroll offset for pagination click
  // const { lockScroll, unlockScroll } = useScrollLock()
  // lockScroll()

  const extractValue = (query: string, key: string): string | undefined => {
    const regexp = `${key}=([^&]*)`
    const matches = query?.match(regexp)
    const res = matches?.[1]
    return res && res != '' ? decodeURIComponent(res.replace(/\+/g, ' ')) : undefined
  }

  const setNewPayload = useCallback(
    (
      query: string | undefined,
      page: number,
      filters: Map<string, Map<string, string>>,
      localisReserve: boolean = isReserve,
      localisFulltime: boolean = isFulltime
    ) => {
      //const order = new Map<string, string>(orderingProps?.targetItems?.map((x) => [x.id, x.fieldName?.value]))
      const isFilterNotEmpty = (filter: [string, Map<string, string>]) => filter[1].size > 0
      const isValidEntryMethod = (filterId: string) => {
        if (localisReserve) {
          return filterId !== generalEntryMethodsProps?.id && filterId !== allEntryMethodsProps?.id
        }
        if (localisFulltime) {
          return filterId !== reserveEntryMethodsProps?.id && filterId !== allEntryMethodsProps?.id
        }
        return filterId !== reserveEntryMethodsProps?.id && filterId !== generalEntryMethodsProps?.id
      }
      const res = {
        filterValues: Array.from(filters.entries())
          .filter((filter) => isFilterNotEmpty(filter) && isValidEntryMethod(filter[0]))
          .map((x) => ({
            FacetId: x[0],
            FacetValues: Array.from(x[1].keys()),
          })),

        searchId: showDegrees ? degreeSearchId : jobSearchId,
        //sortBy: Array.from(filters.get(orderingProps?.id)?.keys() ?? []).map((x) => order.get(x)),
        page: page,
        perPage: perPageCount,
        query: query,
      }
      setPayload(res)
      return res
    },
    [
      generalEntryMethodsProps?.id,
      isReserve,
      isFulltime,
      orderingProps?.id,
      orderingProps?.targetItems,
      perPageCount,
      reserveEntryMethodsProps?.id,
      allEntryMethodsProps?.id,
      showDegrees,
    ]
  )

  const addDegreeSearchFilter = (selectedDegree: string) => {
    const degrees = new Map<string, string>(degreeProps?.children?.results?.map((x) => [x.displayName?.value, x.id]))
    const degreeFilter: Map<string, string> = new Map<string, string>([
      [degrees.get(selectedDegree) ?? '', selectedDegree],
    ])
    filters?.set(degreeProps?.id, degreeFilter)
  }

  // set up filters from query string
  const createFilters = useCallback(
    (path: string) => {
      //const order = new Map<string, string>(orderingProps?.targetItems?.map((x) => [x.name?.value, x.id]))
      const jobTypes = new Map<string, string>(typesProps?.children?.results?.map((x) => [x.name?.value, x.id]))
      const priorityOrders = new Map<string, string>(
        priorityOrderProps?.children?.results?.map((x) => [x.name?.value, x.id])
      )
      const jobServices = new Map<string, string>(servicesProps?.children?.results?.map((x) => [x.name?.value, x.id]))
      const generalEntryMethods = new Map<string, string>(
        generalEntryMethodsProps?.children?.results?.map((x) => [x.name?.value, x.id])
      )
      const reserveEntryMethods = new Map<string, string>(
        reserveEntryMethodsProps?.children?.results?.map((x) => [x.name?.value, x.id])
      )
      const allEntryMethods = new Map<string, string>(
        allEntryMethodsProps?.children?.results?.map((x) => [x.name?.value, x.id])
      )
      const jobCategories = new Map<string, string>(
        categoriesProps?.children?.results?.map((x) => [x.name?.value, x.id])
      )

      //const regexp = `(${orderingProps?.name}=[^&]*)`
      //const matches = path?.match(regexp)
      //const orderQuery = matches?.[1]

      /* const orderFilters =
         !orderQuery || orderQuery.length == 0
           ? new Map<string, string>(
             // add default value if order doesn't exist in URL
             orderingProps?.targetItems?.filter((x) => x.isDefault).map((x) => [x.id, x.name?.value])
           )
           : new Map<string, string>(
             extractValue(path, orderingProps?.name)
               ?.split(',')
               ?.map((x) => [order.get(x) ?? '', x])
           )*/

      const typeRegexp = `(${typesProps?.name?.value}=[^&]*)`
      const typeMatches = path?.match(typeRegexp)
      const typeQuery = typeMatches?.[1]

      const typeFilters =
        !typeQuery || typeQuery.length == 0
          ? new Map<string, string>(
              // SDFOCUS-7273 Remove default filter isFullTime from career search
              // typesProps?.children?.results?.filter((_x, i) => i == 0).map((x) => [x.id, x.name?.value])
              []
            )
          : new Map<string, string>(
              extractValue(path, typesProps?.name?.value)
                ?.split(',')
                ?.map((x) => [jobTypes.get(x) ?? '', x])
            )

      !typeQuery || typeQuery.length == 0
        ? new Map<string, string>(
            // SDFOCUS-7273 Remove default filter isFullTime from career search
            // typesProps?.children?.results?.filter((_x, i) => i == 0).map((x) => [x.id, x.name?.value])
            []
          )
        : new Map<string, string>(
            extractValue(path, typesProps?.name?.value)
              ?.split(',')
              ?.map((x) => [jobTypes.get(x) ?? '', x])
          )

      const filters = new Map<string, Map<string, string>>([
        //[orderingProps?.id, new Map<string, string>(orderFilters)],
        [
          priorityOrderProps?.id,
          new Map<string, string>(
            extractValue(path, priorityOrderProps?.name?.value)
              ?.split(',')
              ?.map((x) => [priorityOrders.get(x) ?? '', x])
          ),
        ],
        [typesProps?.id, new Map<string, string>(typeFilters)],
        [
          servicesProps?.id,
          new Map<string, string>(
            extractValue(path, servicesProps?.name?.value)
              ?.split(',')
              ?.map((x) => [jobServices.get(x) ?? '', x])
          ),
        ],
        [
          reserveEntryMethodsProps?.id,
          new Map<string, string>(
            extractValue(path, reserveEntryMethodsProps?.name?.value)
              ?.split(',')
              ?.map((x) => [reserveEntryMethods.get(x) ?? '', x])
          ),
        ],
        [
          generalEntryMethodsProps?.id,
          new Map<string, string>(
            (extractValue(path, generalEntryMethodsProps?.name?.value)?.split(',') ?? [])
              .concat(preSelectedEntryMethods)
              ?.map((x) => [generalEntryMethods.get(x) ?? '', x])
          ),
        ],
        [
          allEntryMethodsProps?.id,
          new Map<string, string>(
            (extractValue(path, allEntryMethodsProps?.name?.value)?.split(',') ?? [])
              .concat(preSelectedEntryMethods)
              ?.map((x) => [allEntryMethods.get(x) ?? '', x])
          ),
        ],
        [
          categoriesProps?.id,
          new Map<string, string>(
            (extractValue(path, categoriesProps?.name?.value)?.split(',') ?? [])
              // append preSelectedCategory to the array if it exists for category job listing
              .concat(preSelectedCategoryItems)
              .map((x) => [jobCategories.get(x) ?? '', x])
          ),
        ],
      ])
      //console.log(path, !orderQuery || orderQuery.length == 0, new Map<string, string>(orderFilters), filters)
      return filters
    },
    [
      categoriesProps?.children?.results,
      categoriesProps?.id,
      categoriesProps?.name?.value,
      generalEntryMethodsProps?.children?.results,
      generalEntryMethodsProps?.id,
      generalEntryMethodsProps?.name?.value,
      allEntryMethodsProps?.children?.results,
      allEntryMethodsProps?.id,
      allEntryMethodsProps?.name?.value,
      orderingProps?.id,
      orderingProps?.name,
      orderingProps?.targetItems,
      preSelectedCategoryItems,
      preSelectedEntryMethods,
      reserveEntryMethodsProps?.children?.results,
      reserveEntryMethodsProps?.id,
      reserveEntryMethodsProps?.name?.value,
      servicesProps?.children?.results,
      servicesProps?.id,
      servicesProps?.name?.value,
      typesProps?.children?.results,
      typesProps?.id,
      typesProps?.name?.value,
    ]
  )

  const hasFilters = () => {
    return (
      (filters.get(priorityOrderProps?.id)?.size ?? 0) +
        (filters.get(typesProps?.id)?.size ?? 0) +
        (filters.get(servicesProps?.id)?.size ?? 0) +
        (filters.get(entryMethodsProps?.id)?.size ?? 0) +
        (filters.get(categoriesProps?.id)?.size ?? 0) >
      0
    )
  }

  const stickyListener = useRef<HTMLDivElement | null>(null)
  const searchField = useRef<HTMLDivElement | null>(null)
  const searchElement = useRef<HTMLDivElement | null>(null)
  const searchResults = useRef<HTMLDivElement | null>(null)
  const searchTags = useRef<HTMLDivElement | null>(null)

  const [searchPos, setSearchPos] = useState<true | false | undefined>(undefined)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [results, setResults] = useState(props?.results)
  const [routeQuery, setRouteQuery] = useState<string>(extractValue(router.asPath, 'query') ?? '')
  const [tempFilters, setTempFilters] = useState(() => createFilters(router.asPath))
  const [filters, setFilters] = useState(() => createFilters(router.asPath))
  const [payload, setPayload] = useState<SearchResultsRequest | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [keywords, setKeywords] = useState<KeywordSuggestionsResponse | undefined>(undefined)

  const entryIntersect = useIntersect(stickyListener, '0px 0px 500px 0px')

  const tickFilter = (keyId: string, keyName: string, value: FilterValue) => {
    const map = filters.get(keyId)
    if (!map) return
    const isFilter = map.has(value.value)
    if (isFilter) {
      map?.delete(value.value)
    } else {
      map?.set(value.value, value.queryName ?? '')
    }
    router.push(
      {
        query: {
          ...router.query,
          ...{
            [keyName]: Array.from(map.values())
              .map((x) => x)
              .join(','),
            page: 1,
          },
        },
      },
      undefined,
      {
        shallow: true,
      }
    )

    setFilters(filters)
    const query = extractValue(router.asPath, 'query')
    setNewPayload(query, 1, filters)
  }

  const trackAppliedFilterData = (query: Record<string, string>) => {
    if (query) {
      const filterSection: string[] = []
      for (const key of Object.keys(query)) {
        if (key && query[key]) {
          const sectionKey: HeadingKeys = HeadingKeysMap[key as HeadingKeyType]
          filterSection.push(`${props?.fields?.data?.datasource[sectionKey].value}-${query[key]}`)
        }
      }
      const selectedFilterOptions = `${filterSection.join(';')} - ${props.rendering.uid}` as const
      trackFilterInteraction('applied', 'job filter', selectedFilterOptions)
    }
  }
  const applyQuery = (filters: Map<string, Map<string, string>>, isReserve: boolean, isFullTime: boolean) => {
    //const orderingMap = filters.get(orderingProps?.id) ?? new Map<string, string>()
    const priorityOrderMap = filters.get(priorityOrderProps?.id) ?? new Map<string, string>()
    const typesMap = filters.get(typesProps?.id) ?? new Map<string, string>()
    const servicesMap = filters.get(servicesProps?.id) ?? new Map<string, string>()
    const entryMethodsMap =
      filters.get(
        isReserve ? reserveEntryMethodsProps?.id : isFullTime ? generalEntryMethodsProps?.id : allEntryMethodsProps?.id
      ) ?? new Map<string, string>()
    const categoriesMap = filters.get(categoriesProps?.id) ?? new Map<string, string>()
    const query = {
      //[orderingProps?.name]: Array.from(orderingMap?.values()).join(','),
      [priorityOrderProps?.name.value]: Array.from(priorityOrderMap?.values()).join(','),
      [typesProps?.name?.value]: Array.from(typesMap?.values()).join(','),
      [servicesProps?.name?.value]: Array.from(servicesMap?.values()).join(','),
      [entryMethodsProps?.name?.value]: Array.from(entryMethodsMap?.values()).join(','),
      [categoriesProps?.name?.value]: Array.from(categoriesMap?.values()).join(','),
    }
    //console.log('query-:' + query);
    router.push(
      {
        query: {
          ...router.query,
          ...query,
          page: 1,
        },
      },
      undefined,
      {
        shallow: true,
      }
    )
    trackAppliedFilterData(query)
  }

  useEffect(() => {
    //console.log('Payload has been changed')
    if (!payload || sitecoreContext?.pageEditing == true) {
      return
    }

    const ctrl = {
      isAborted: false,
    }

    setIsLoading(true)
    setKeywords(undefined)

    fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/jobSearch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((r) => {
        if (!props?.results && !ctrl.isAborted) {
          setResults(r)
          if ((r?.TotalCount ?? 0) == 0 && !showDegrees) {
            const keywordRequest: KeywordSuggestionsRequest = {
              index: '3E5609FF-1517-4EBE-9037-3EC539AF3D24',
              query: payload?.query ?? '',
            }
            fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/keywordSuggestion`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(keywordRequest),
            })
              .then((r) => r.json())
              .then((r) => {
                if (!ctrl.isAborted) {
                  setKeywords(r)
                }
              })
              .finally(() => setIsLoading(false))
          } else {
            if (showDegrees && (r?.TotalCount ?? 0) == 0) {
              setKeywords({})
            } else {
              setKeywords(undefined)
            }
            setIsLoading(false)
          }
        }
      })
      .catch(() => setIsLoading(false))

    return () => {
      ctrl.isAborted = true
    }
  }, [payload, props?.results, showDegrees, sitecoreContext?.pageEditing])

  useEffect(() => {
    // console.log(entryIntersect ? entryIntersect?.intersectionRatio * 100 : '?')
    const entryRatio = entryIntersect?.intersectionRatio ? entryIntersect?.intersectionRatio * 100 : 0
    if (entryRatio) entryRatio < 92 && !showDegrees ? setSearchPos(true) : setSearchPos(false)
  }, [entryIntersect, showDegrees])

  useEffect(() => {
    const qLink = document.querySelector('.quicklink-holder a')
    if (qLink) qLink.addEventListener('focus', focusInView)
    return () => qLink?.removeEventListener('focus', focusInView)
  }, [])

  useEffect(() => {
    // console.log('searchPos', searchPos)
    const searchFieldVar = searchField.current as HTMLDivElement
    const searchElementVar = searchElement.current as HTMLDivElement
    const searchResultsVar = searchResults.current as HTMLDivElement
    const searchTagsVar = searchTags.current as HTMLDivElement
    const searchRect = searchElement.current?.getBoundingClientRect()

    if (searchPos) {
      if (searchElementVar && searchRect) {
        searchElementVar.style.height = searchRect.height + 'px'
      }
      if (searchFieldVar) {
        // const elmPosition = searchRect
        // const topFixed = elmPosition?.top
        searchFieldVar.style.top = `64px` //`${topFixed}px`
        searchFieldVar.style.position = 'fixed'
        searchFieldVar.style.transform = `translateY(-${64 - 45}px)` //`translateY(-${(topFixed || 0) - 45}px)`
      }

      if (searchResultsVar && searchTagsVar) {
        searchResultsVar.style.transform = `translateY(-${searchResultsVar.offsetTop - searchTagsVar.offsetTop}px)`
      }
    } else if (searchPos === false) {
      if (searchElementVar) searchElementVar.style.height = 'auto'
      if (searchResultsVar) searchResultsVar.style.transform = `translateY(0px)`
      if (searchFieldVar) {
        searchFieldVar.style.transform = `translateY(0px)`
        searchFieldVar.style.position = 'initial'
      }
    }
  }, [searchPos])

  // This initialises the page query on load.
  // @todo - check if this is even required now as we use the search params for state.
  useEffect(() => {
    if (!showDegrees) {
      const page = extractValue(router.asPath, 'page')
      setTimeout(() => {
        setNewPayload(routeQuery, Number(page), filters)
      })
    }
    // purposefully not including anything as this entire search function should be rewritten to use the search params as state.
  }, [])

  //force load results when search bar is hidden
  useEffect(() => {
    if (hideSearchBar) {
      const filters = createFilters('')
      setFilters(filters)
      setCurrentPage(1)
      setNewPayload('', 1, filters)
    }
  }, [hideSearchBar])

  const sectionClasses = `
        relative mb-8
    `
  const columnLGLessGutters = `
        lg:!pr-[calc(var(--colwidth)-var(--gutter)/2)] lg:!pl-[calc(var(--colwidth)-var(--gutter)/2)]
        xxl:!pr-[calc(var(--colwidth)*2-var(--gutter)/2)] xxl:!pl-[calc(var(--colwidth)*2-var(--gutter)/2)]
    `
  const dataColsOverride = `!px-[var(--gutter)] !mx-0 !max-w-full`

  const topSearchClasses = `
        [&_.adjust]:!p-0 [&_.adjust]:!m-0 [&_.adjust]:!w-full [&_.adjust]:!max-w-full
        [&_.search-inner]:!mx-6 lg:[&_.search-inner]:!mx-16 xl:[&_.search-inner]:!mx-18 xxl:[&_.search-inner]:!mx-[120px] [&_.search-inner]:!mb-1
        [&_input]:!lbody
        [&_.search-title]:opacity-0
        [&_+_.search-tags]:opacity-0
        [&_+_.search-tags_+_.search-banner]:opacity-0
        [&_.icon-search]:!h-[22px] [&_.icon-search]:!w-[22px]
        [&_.sub-filter]:!h-0 [&_.sub-filter]:!p-0 [&_.sub-filter]:!opacity-0
    `
  const transitionClasses = `
        transition-all duration-[500ms] ease-out
    `
  const breadcrumbFollow = `
    breadcrumbFollow ${
      pageState.pageScroll?.scrollDirection && searchPos ? 'translate-y-16 xl:translate-y-20' : 'translate-y-0'
    }
    transition-all duration-[500ms] ease-out
    delay-[73ms] ease-linear
  `

  const updateResults = (query: string, selectedDegree: string, seeAllQuerySuggestion: string = '') => {
    // don't reload for special sequence
    if (!selectedDegree && query == '-----') return

    // don't reload if payload already set
    if (!selectedDegree && query == payload?.query) return

    if (selectedDegree && selectedDegree.trim() !== '') {
      addDegreeSearchFilter(selectedDegree)
    }
    setNewPayload(query, 1, filters)
    setCurrentPage(1)

    // Check if the current router query has multiple keywords
    //const hasMultipleKeywords = (router.query.query as string)?.split(',').length > 1

    //construct parsedurlquery
    const updatedQuery: ParsedUrlQuery = {
      ...router.query,
      query: query,
      page: '1', // Convert to string as query parameters are typically strings
    }
    // Add or remove the 'inputQuery' parameter based on the seeAllQuerySuggestion is set
    if (seeAllQuerySuggestion) {
      updatedQuery['inputQuery'] = seeAllQuerySuggestion
    } else {
      delete updatedQuery['inputQuery']
    }
    router.push(
      {
        pathname: router.pathname,
        query: updatedQuery,
      },
      undefined,
      {
        shallow: true,
      }
    )

    track({
      event: 'search_completed',
      ga4category: `In-page - Search`,
      searchinitiated_page: router.asPath,
      ga4name: query,
    })
  }

  return (
    <div className={`${!hideSearchBar ? 'content-inner pb-20 pt-14 lg:pt-22 xl:pt-20 xxl:pt-[120px] ' : ''}`}>
      <div className="intersection-observer absolute left-0 top-[-1px] h-[1000px] w-2" ref={stickyListener} />
      <button
        className="mb-3 hidden"
        onClick={() =>
          fetch(`${process.env.HUB_API_HOST}/api/v2/search/JobSearch`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          })
            .then((r) => r.json())
            .then((r) => console.log(r))
        }
      >
        <FilterTag tagText="Test direct call" />
      </button>

      {!hideSearchBar && (
        <section
          className={`SEARCH-HEAD relative z-[49] h-[100px] bg-white xm:mb-8 ${searchPos ? topSearchClasses : ''}`}
          ref={searchElement}
        >
          <div className={`w-full bg-white ${transitionClasses} adjust`} ref={searchField}>
            <div data-cols="12" className={`SEARCH-STICKY adjust relative bg-white ${breadcrumbFollow}`}>
              <div className={` ${transitionClasses} adjust`}>
                <div className={`span-12 ${columnLGLessGutters} ${transitionClasses} adjust`}>
                  {!showDegrees && (
                    <SearchField
                      routeQuery={router.asPath}
                      focusField={true}
                      inPage={true}
                      searchPos={searchPos}
                      searchTitle="Career Search"
                      placeholderText="Start typing"
                      filter={
                        //(filters.get(orderingProps?.id)?.size ?? 0) +
                        (filters.get(priorityOrderProps?.id)?.size ?? 0) +
                        (filters.get(typesProps?.id)?.size ?? 0) +
                        (filters.get(servicesProps?.id)?.size ?? 0) +
                        (filters.get(entryMethodsProps?.id)?.size ?? 0) +
                        (filters.get(categoriesProps?.id)?.size ?? 0)
                      }
                      location={false}
                      isJobSearch
                      onclickFilter={() => {
                        setTempFilters(
                          new Map<string, Map<string, string>>([
                            // [orderingProps?.id, new Map<string, string>(filters.get(orderingProps?.id))],
                            [priorityOrderProps?.id, new Map<string, string>(filters.get(priorityOrderProps?.id))],
                            [typesProps?.id, new Map<string, string>(filters.get(typesProps?.id))],
                            [servicesProps?.id, new Map<string, string>(filters.get(servicesProps?.id))],
                            [
                              generalEntryMethodsProps?.id,
                              new Map<string, string>(filters.get(generalEntryMethodsProps?.id)),
                            ],
                            [
                              reserveEntryMethodsProps?.id,
                              new Map<string, string>(filters.get(reserveEntryMethodsProps?.id)),
                            ],
                            [allEntryMethodsProps?.id, new Map<string, string>(filters.get(allEntryMethodsProps?.id))],
                            [categoriesProps?.id, new Map<string, string>(filters.get(categoriesProps?.id))],
                          ])
                        )
                        setTimeout(() => setOpenDrawer(!openDrawer), 10)
                      }}
                      updateResults={(query, selectedDegree) => {
                        //DSOR-18 disable autocomplete by config
                        if (!showAutoComplete || (selectedDegree && selectedDegree != '')) {
                          updateResults(query, '')
                        } else {
                          send({ type: 'UPDATE_QUERY', query: query ?? '' })
                        }
                      }}
                      // autocomplete fields
                      queryList={{
                        TotalCount: autocomplete?.length ?? 0,
                        Results: autocomplete,
                        Message: null,
                      }}
                      showTotal={false}
                      onClickTotal={(query: string, seeAllQuerySuggestion: string) => {
                        send({ type: 'STOP_AUTOCOMPLETE', query })
                        //set handle seeall, pass initial input query
                        updateResults(query, '', seeAllQuerySuggestion)
                      }}
                      searchClicked={(query) => {
                        send({ type: 'STOP_AUTOCOMPLETE', query })
                        updateResults(query, '')
                      }}
                      onQueryClick={(query) => {
                        send({ type: 'STOP_AUTOCOMPLETE', query })
                        updateResults(query, '')
                      }}
                      onResetClick={() => {
                        send({ type: 'STOP_AUTOCOMPLETE', query: '' })
                        updateResults('', '')
                      }}
                    />
                  )}
                  {showDegrees && (
                    <CustomSelect
                      routeQuery={routeQuery}
                      degreeResults={degreeProps?.children?.results}
                      degreeSearchTitle={degreeSearchTitle}
                      degreeSearchPlaceholder={degreeSearchPlaceholder}
                      showDegrees={showDegrees}
                      updateResults={updateResults}
                      removeDegreeSearchResults={() => {
                        setResults({ TotalCount: 0, Results: [], Message: null })
                        setKeywords(undefined)
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {!showDegrees && !hideSearchBar && hasFilters() && (
        <section className={`SEARCH-TAGS search-tags ${sectionClasses} ${transitionClasses}`} ref={searchTags}>
          <div data-cols="12" className="relative">
            <div className="row">
              <div className={`span-12 ${columnLGLessGutters}`}>
                <div className="mb-2 flex flex-col justify-between md:flex-row md:items-start md:gap-x-3">
                  <div className="filter-tags flex flex-wrap gap-x-2 gap-y-3 py-2">
                    {/*orderingProps?.targetItems?.map((x) => {
                      if (filters.get(orderingProps?.id)?.has(x.id)) {
                        return (
                          <button
                            key={x.id}
                            onClick={() =>
                              tickFilter(orderingProps?.id, orderingProps?.name, {
                                name: x?.description?.value ?? '',
                                value: x?.id ?? '',
                                queryName: x?.name?.value,
                              })
                            }
                          >
                            <FilterTag tagText={x.name?.value} />
                          </button>
                        )
                      } else {
                        return undefined
                      }
                    })*/}
                    {priorityOrderProps?.children?.results?.map((x) => {
                      if (filters.get(priorityOrderProps?.id)?.has(x.id)) {
                        return (
                          <button
                            key={x.id}
                            onClick={() =>
                              tickFilter(priorityOrderProps?.id, priorityOrderProps?.name?.value, {
                                name: x?.displayName?.value ?? '',
                                value: x?.id ?? '',
                                queryName: x?.name?.value,
                              })
                            }
                          >
                            <FilterTag tagText={x.name?.value} />
                          </button>
                        )
                      } else {
                        return undefined
                      }
                    })}
                    {typesProps?.children?.results?.map((x) => {
                      if (filters.get(typesProps?.id)?.has(x.id)) {
                        return (
                          <button
                            key={x.id}
                            onClick={() =>
                              tickFilter(typesProps?.id, typesProps?.name?.value, {
                                name: x?.displayName?.value ?? '',
                                value: x?.id ?? '',
                                queryName: x?.name?.value,
                              })
                            }
                          >
                            <FilterTag tagText={x.name?.value} />
                          </button>
                        )
                      } else {
                        return undefined
                      }
                    })}
                    {servicesProps?.children?.results?.map((x) => {
                      if (filters.get(servicesProps?.id)?.has(x.id)) {
                        return (
                          <button
                            key={x.id}
                            onClick={() =>
                              tickFilter(servicesProps?.id, servicesProps?.name?.value, {
                                name: x?.displayName?.value ?? '',
                                value: x?.id ?? '',
                                queryName: x?.name?.value,
                              })
                            }
                          >
                            <FilterTag tagText={x.name?.value} />
                          </button>
                        )
                      } else {
                        return undefined
                      }
                    })}
                    {entryMethodsProps?.children?.results?.map((x) => {
                      if (filters.get(entryMethodsProps?.id)?.has(x.id)) {
                        return (
                          <button
                            key={x.id}
                            onClick={() =>
                              tickFilter(entryMethodsProps?.id, entryMethodsProps?.name?.value, {
                                name: x?.displayName?.value ?? '',
                                value: x?.id ?? '',
                                queryName: x?.name?.value,
                              })
                            }
                          >
                            <FilterTag tagText={x.name?.value} />
                          </button>
                        )
                      } else {
                        return undefined
                      }
                    })}
                    {categoriesProps?.children?.results?.map((x) => {
                      if (filters.get(categoriesProps?.id)?.has(x.id)) {
                        return (
                          <button
                            key={x.id}
                            onClick={() =>
                              tickFilter(categoriesProps?.id, categoriesProps?.name?.value, {
                                name: x?.displayName?.value ?? '',
                                value: x?.id ?? '',
                                queryName: x?.name?.value,
                              })
                            }
                          >
                            <FilterTag tagText={x.name?.value} />
                          </button>
                        )
                      } else {
                        return undefined
                      }
                    })}
                  </div>
                  <div className="clear-filter mb-3 mt-2 self-start md:mt-5 md:h-6">
                    <Button
                      data-trackingid={props.rendering.uid as string}
                      button
                      onClick={() => {
                        router
                          .push(
                            {
                              query: {
                                ...router.query,
                                ...{
                                  query: undefined,
                                  page: 1,
                                  //[orderingProps?.name]: undefined,
                                  [priorityOrderProps?.name.value]: undefined,
                                  [typesProps?.name?.value]: undefined,
                                  [entryMethodsProps?.name?.value]: undefined,
                                  [categoriesProps?.name?.value]: undefined,
                                  [servicesProps?.name?.value]: undefined,
                                },
                              },
                            },
                            undefined,
                            {
                              shallow: true,
                            }
                          )
                          .then(() => {
                            setIsReserve(false)
                            setIsFulltime(false)
                            const filters = createFilters(`${typesProps?.name?.value}=`)
                            setFilters(filters)
                            setCurrentPage(1)
                            setNewPayload('', 1, filters)
                            // trigger update if it set to empty before
                            setRouteQuery('-----')
                            setTimeout(() => setRouteQuery(''), 1)
                          })
                      }}
                      type="tertiary-action"
                      icon="refresh"
                      link={{ value: { text: 'Clear All' } }}
                      service="tri-service"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {!hideSearchBar && !keywords && (
        <section className={`SEARCH-BANNER search-banner ${sectionClasses} ${transitionClasses}`}>
          <div className="relative" data-cols={12}>
            <div className="row">
              <div
                className={`quicklink-holder span-12 ${columnLGLessGutters} [&>.sc-jss-empty-placeholder>*:nth-child(n+4)]:hidden`}
              >
                <Placeholder name={phKey} rendering={props.rendering} />
              </div>
            </div>
          </div>
        </section>
      )}

      <section
        className={`SEARCH-RESULTS ${sectionClasses} ${isLoading ? 'h-[200px] ' : ''} ${transitionClasses}`}
        ref={searchResults}
      >
        {isLoading ? (
          <span className="absolute inset-y-0 flex w-full items-center justify-center">
            <div className="svg-loading">
              <Icon name="chevron-right-double" type="stroke" width={120} height={120} className="text-grey-light" />
            </div>
          </span>
        ) : (
          <div data-cols="12" className={`relative ${hideSearchBar ? dataColsOverride : ''}`}>
            <div className="row">
              {results && results?.TotalCount > 0 && (
                <div className={`span-12 ${!hideSearchBar ? columnLGLessGutters : ''}`}>
                  {!hideSearchBar && (
                    <>
                      <div className="border-b border-grey-light pb-6">
                        <span className="sbody mr-2">
                          Showing: {(currentPage - 1) * resultsPerPage + 1}-
                          {Math.min(currentPage * resultsPerPage, results.TotalCount)} of {results.TotalCount} results
                        </span>
                      </div>
                    </>
                  )}
                  <div className="results px-[calc(var(--gutter)_/_2)]">
                    {results.Results.slice(0, 20).map((job) => (
                      <JobCard key={job.id} query={query} jobDetails={job} displayPriorityTag={true} />
                    ))}
                  </div>
                </div>
              )}
              <SearchNoResults
                keywords={keywords}
                query={payload?.query ?? ''}
                type={showDegrees ? 'degrees' : 'jobs'}
                showTips={!showDegrees}
                onClick={(word: string) => {
                  router
                    .push(
                      {
                        query: {
                          ...router.query,
                          query: word,
                          page: 1,
                        },
                      },
                      undefined,
                      {
                        shallow: true,
                      }
                    )
                    .then(() => {
                      setCurrentPage(1)
                      setNewPayload(word, 1, filters)
                      setRouteQuery(word)
                    })
                }}
              />
            </div>
            <div className="row mt-6">
              <div className={`span-12 ${columnLGLessGutters}`}>
                {!hideSearchBar && results && results?.TotalCount > 0 && (
                  <Pagination
                    itemCount={results?.TotalCount}
                    currentPage={currentPage}
                    setCurrentPage={(page) => {
                      setCurrentPage(page)
                      router.push(
                        {
                          query: {
                            ...router.query,
                            page: page.toString(),
                          },
                        },
                        undefined,
                        {
                          shallow: true,
                        }
                      )
                      const query = extractValue(router.asPath, 'query')
                      setNewPayload(query, page, filters)
                      setTimeout(() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                      }, 1000)
                    }}
                    resultsPerPage={resultsPerPage}
                    scrollOffset={scrollOffset}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </section>
      <SearchDrawer
        props={props}
        openDrawer={openDrawer}
        filters={tempFilters}
        isReserve={isReserve}
        isFulltime={isFulltime}
        onClose={() => {
          setOpenDrawer(false)
        }}
        onReset={() => {
          setIsReserve(false)
          setIsFulltime(false)
          setOpenDrawer(false)
          router
            .push(
              {
                query: {
                  ...router.query,
                  ...{
                    //[orderingProps?.name]: undefined,
                    [typesProps?.name?.value]: undefined,
                    [priorityOrderProps?.name?.value]: undefined,
                    [entryMethodsProps?.name?.value]: undefined,
                    [categoriesProps?.name?.value]: undefined,
                    [servicesProps?.name?.value]: undefined,
                    page: 1,
                  },
                },
              },
              undefined,
              {
                shallow: true,
              }
            )
            .then(() => {
              const filters = createFilters('')
              setFilters(filters)
              setCurrentPage(1)
              setNewPayload('', 1, filters)
            })
        }}
        onApply={(isReserve, isFulltime) => {
          setOpenDrawer(false)
          setIsReserve(isReserve)
          setIsFulltime(isFulltime)
          setCurrentPage(1)
          setFilters(tempFilters)
          applyQuery(tempFilters, isReserve, isFulltime)
          const query = extractValue(router.asPath, 'query')
          setNewPayload(query, 1, tempFilters, isReserve, isFulltime)
          console.log(tempFilters)
        }}
      />
    </div>
  )
}

export default ADFSearchResults
